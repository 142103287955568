import React, { useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function Home(props) {
  const { currentUser } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const [isAppReady, setIsAppReady] = useState(false);

  const buttonClick = () => {
    const name = currentUser.firstName + " " + currentUser.lastName;
    window.flutter_inappwebview.callHandler('settingsAuthenticationApp', currentUser.id, name, currentUser.loginId);
  };

  return (
    <>
      <Card>
        <CardHeader className="card-header-toolbar"></CardHeader>
        <CardBody className="p-b-0">
          <button
            className="btn btn-info btn-cons m-t-12 btn-block w-5" style={{marginBottom: "50px"}}
            type="button"
            onClick={buttonClick}>
            {t('home.button.passkey')}
          </button>
        </CardBody>
      </Card>
                           
    </>
  );
}

export default Home;
