export const randomString = len =>
  Array(len)
    .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
    .map(function(x) {
      return x[Math.floor(Math.random() * x.length)];
    })
    .join('');

export const validURL = str => {
  try {
    if (!str.startsWith('http://') && !str.startsWith('https://')) return false;

    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};

export const compareString = (a, b) => {
  const _a = a ? a.toLowerCase() : '';
  const _b = b ? b.toLowerCase() : '';
  if (_a < _b) return -1;
  if (_a > _b) return 1;
  return 0;
};

/* convert string from full to half width
 *		１２３ to 123
 *   'space' full to half width
 */
export const toHalfWidth = str => {
  const value = str
    .replace(/[\uff01-\uff5e]/g, ch => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
    .replace(/\u3000/g, '\u0020')
    .replace(/[ー−]/g, '\u002D');
  return value;
};

export const getEnvName = env => {
  let envName = 'Local';
  switch (env) {
    case 'development':
      envName = 'Testing';
      break;
    case 'nightly':
      envName = 'Alpha';
      break;
    case 'prod':
    case 'production':
      envName = 'Production';
      break;
    case 'dev':
      envName = 'Eval';
      break;
    case 'staging':
      envName = 'Beta';
      break;
    default:
      break;
  }
  return envName;
};

export const encodeBase64 = str => {
  return Buffer.from(str).toString('base64');
};

export const decodeBase64 = str => {
  try {
    if (str) {
      return Buffer.from(str, 'base64').toString();
    }
  } catch (error) {
    console.error('decodeBase64 -> error -> ', error);
  }
  return str;
};
