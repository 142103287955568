import validator from 'validator';
import {
  mobilePhoneLength,
  DOMAIN_NAME_MAX_LENGTH,
  USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH,
  EMAIL_MAX_LENGTH
} from 'core/constants';

validator.atLeastOne = obj => {
  if (!obj) return false;
  if (Array.isArray(obj)) return obj.length > 0;
  if (typeof obj === 'object') return Object.keys(obj).length > 0;
  return false;
};

validator.isRequired = obj => {
  if (!obj) return false;

  if (Array.isArray(obj)) return obj.length > 0;

  if (typeof obj === 'object') return Object.keys(obj).length > 0;

  let val = typeof obj === 'string' ? obj.trim() : '1';

  return val !== '';
};

validator.isRequiredSelect = obj => {
  if (!obj) return false;

  if (Array.isArray(obj)) return obj.length > 0;

  if (typeof obj === 'object') return Object.keys(obj).length > 0;

  let val = typeof obj === 'string' ? obj.trim() : '1';

  return val !== '';
};

// validator.isCorrectEmail = emailVal => {
//   const dotsRegex = /\.\./g;
//   const local_lastDotRegex = /.*\.@/; //local part: not accept dot in the end
//   const domain_lastDotRegex = /@.*\.$/; //domain part : not accept dot in the end

//   if (
//     dotsRegex.test(emailVal) ||
//     emailVal.endsWith('-') ||
//     local_lastDotRegex.test(emailVal) ||
//     domain_lastDotRegex.test(emailVal) ||
//     emailVal.length <= 5 ||
//     emailVal.length >= 320
//   )
//     return false;

//   const pattern = /^[0-9A-Za-z!#$%&'*+\-/=?^_`{|}~]([-0-9A-Za-z!#$%&'*+/=?^_`{|}~.]{0,63})@[0-9A-Za-z][-0-9A-Za-z.]{0,254}$/;

//   return pattern.test(emailVal);
// };

validator.isCorrectEmail = obj => {
  if (!obj) return true;
  const validEmail = validator.isEmail(obj, { allow_utf8_local_part: false, ignore_max_length: true });
  const emailParts = obj.split('@');
  const blackListChars = ['"'];
  if (
    !validEmail ||
    Buffer.byteLength(obj, 'utf8') !== obj.length ||
    blackListChars.some(c => obj.includes(c)) ||
    obj.length > EMAIL_MAX_LENGTH ||
    emailParts.length !== 2 ||
    emailParts[0].length > USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH ||
    emailParts[1].length > DOMAIN_NAME_MAX_LENGTH
  ) {
    return false;
  }

  return true;
};

validator.isPhoneNumber = obj => {
  if (!obj) return true;
  const regexPhone = /^[+]*[(+]{0,2}[0-9]{0,4}[)]{0,1}[*#]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[*#]{0,1}([-]{0,1}\d([*#]{0,1}))*$/; /* eslint-disable-line */
  obj = obj.toString();
  if (!regexPhone.test(obj) || obj.length > mobilePhoneLength) {
    return false;
  } else {
    if (obj.split('(').length - 1 === 1 && obj.split(')').length - 1 < 1) {
      return false;
    } else if (obj.split(')').length - 1 === 1 && obj.split('(').length - 1 < 1) {
      return false;
    } else if (obj.split(')').length - 1 > 1) {
      return false;
    } else if (obj.split('(').length - 1 > 1) {
      return false;
    } else if (obj.split('()').length - 1 >= 1 || obj.split('(+)').length - 1 >= 1) {
      return false;
    } else {
      if (obj.split('+').length - 1 > 1 || obj.length < 3) {
        return false;
      } else {
        return true;
      }
    }
  }
};

validator.isDomainIP = obj => {
  if (!obj) return true;
  obj = obj.toString();
  const isDomain = validator.isFQDN(obj);
  const isIP = validator.isIP(obj);
  return isDomain || isIP;
};

validator.isIPAddress = obj => {
  if (!obj) return true;
  obj = obj.toString();
  const isIP = validator.isIP(obj);
  return isIP;
};

validator.isSubDomain = obj => {
  if (!obj) return true;
  obj = obj.toString();
  const isSubDomain = /^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]$/.test(obj);
  return isSubDomain;
};

validator.isGreaterThan = (obj, minVal) => {
  if (Number.isNaN(obj)) return false;
  return obj > minVal;
};

validator.isLessThan = (obj, maxVal) => {
  if (!obj) return true;
  return obj < maxVal;
};

validator.isHTTPS = obj => {
  const validURL = validator.isURL(obj, {
    protocols: ['https'],
    require_protocol: true,
    require_valid_protocol: true
  });
  return validURL;
};

validator.isScopeName = obj => {
  if (!obj) return true;
  const isScopeName = /^([_:.-])$/.test(obj);
  return !isScopeName;
};

validator.minLength = (obj, minVal) => {
  if (!obj) return true;
  obj = obj.toString();
  return obj.length >= minVal;
};

validator.isPreventJapaneseCharacter = obj => {
  if (!obj) return true;
  //Check input value don't allow input special characters
  const result = /^[a-zA-Z0-9]+$/.test(obj);
  if (!result) return false;
  return true;
};
export const CustomValidator = validator;
export default validator;
