import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import TextCounter from 'components/TextCounter';
import MoreTextWithToolTip from 'components/MoreTextWithToolTip';

export default class BaseLabelCounter extends PureComponent {
  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    length: PropTypes.number,
    required: PropTypes.bool,
    showCounter: PropTypes.bool,
    showHelper: PropTypes.bool,
    contentHelper: PropTypes.string
  };
  static defaultProps = {
    required: true,
    showCounter: true
  };

  render() {
    const { label, value, length, required, contentHelper, showHelper, showCounter: show, ...props } = this.props;
    const showCounter = show && value && length;
    return (
      <div style={{ whiteSpace: 'nowrap' }} {...props}>
        {required && <span style={{ color: 'red', marginRight: '3px' }}>*</span>}
        <Label className="inline">{label}</Label>
        {showCounter && <TextCounter data={value} max={length} wrapClass="m-l-5" />}
        {showHelper && (
          <MoreTextWithToolTip tip={contentHelper} className={'d-inline-flex m-l-5'}>
            <i className="material-icons text-muted" style={{ cursor: 'context-menu', fontSize: '22px' }}>
              help_outline
            </i>
          </MoreTextWithToolTip>
        )}
      </div>
    );
  }
}
