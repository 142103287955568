import React, { useState, useRef, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'element-react';
import SideBar from 'components/SideBar';
import Footer from 'components/Footer';
import TopNav from 'components/TopNav';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { cookies, resetLocalStorage } from 'core/utils';
import { user as userService } from 'services';

import { COOKIE_KEY_USER_TOKEN } from 'core/constants';

function Layout(props) {
  const { t } = useTranslation();
  const { updateFullLoading, updateUser, fullloading, location, setLoginIdStore, currentUser } = props;
  const [sidebarElement, setSidebarElement] = useState(null);
  const page = useRef(null);
  const history = useHistory();

  const sideBarCallback = curr => {
    setSidebarElement(curr);
  };

  const renderPageTitle = locationPath => {
    let title = '';
    switch (locationPath) {
      default:
        title = '';
    }
    return title;
  };

  const signOut = (displayText, noCancel) => {
    history.push('/tci');
  };

  const doSignOut = () => {
    resetLocalStorage();
    cookies.removeAllTokens();
    setLoginIdStore(null);
    updateUser({
      id: null,
      loginId: null,
      firstName: null,
      lastName: null
    });
    history.push('/tci');
  };

  useEffect(() => {
    async function fetchData() {
      try {
        // You can await here
        updateFullLoading(true);
        const userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
        if (!userToken) {
          return history.push('/tci');
        }
        const userInfo = currentUser;
        
        if (!userInfo.id) {
          const res = await userService.getUser(userToken);
          //onsole.log(res.response.status);
          //updateUser(res.data.userInfo);
          if (res.data)
          {
            updateUser(res.data.userInfo);
          }
          else {
            if (res.response.data.code && res.response.data.code === "token-expired" ) {
              this.doSignOut();
            }
          }

        }
        // if (userInfo) {
        //  // process if have user infor
        // }
      } catch (err) {
        //return history.push('/tci');
      } finally {
        updateFullLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div className="">
        <SideBar user={currentUser} callback={sideBarCallback} sections={[]} />

        <div className="page-container" ref={page}>
          <TopNav
            display={false}
            page={page}
            nodes={sidebarElement}
            signOut={doSignOut}
            user={currentUser}
            history={history}
            location={location}
          />

          <div className="page-content-wrapper">
            <div className="content sm-gutter">
              <div className="container-fluid container-fixed-lg container-fixed-xs">
                {/*<!-- START CHILDREN -->*/}
                <h3>{renderPageTitle('test')}</h3>
                <div className="row">
                  <div className="col-12"> {props.children} </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      {fullloading && <Loading fullscreen={true} />}
    </Fragment>
  );
}

Layout.propTypes = {
  notification: PropTypes.object,
  updateNotification: PropTypes.func,
  currentUser: PropTypes.object,
  updateUser: PropTypes.func,
  updateFullLoading: PropTypes.func,
  fullloading: PropTypes.bool,
  location: PropTypes.object,
  addSection: PropTypes.func,
  sections: PropTypes.any,
  resource: PropTypes.any,
  addResources: PropTypes.func,
  updateResourceModal: PropTypes.func
};

export default Layout;
