import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/logo_white.png';
import logo2x from '../../assets/img/logo_white_2x.png';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

class Sidebar extends Component {
  static propTypes = {
    callback: PropTypes.func,
    updateResourceModal: PropTypes.func,
    user: PropTypes.object,
    sections: PropTypes.array,
    t: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      show: localStorage.getItem('pinnedMenu') ? true : false,
      sideBarWidth: 280 - 70,
      subOpen: ''
    };
    this.sidebar = React.createRef();
  }

  componentDidMount() {
    this.toggleMenuPin();
    this.props.callback(this.sidebar);
    window.addEventListener('resize', this.toggleMenuPin);
    const { location } = window;
    const pathname = location.pathname;
    this.setState({
      subOpen: pathname.startsWith('/section') ? 'home' : ''
    });
    this.getCurrentEnvName();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.toggleMenuPin);
  }

  isVisibleMd() {
    const element = document.getElementById('pg-visible-md');
    return element.offsetParent !== null;
  }

  isVisibleXs() {
    const element = document.getElementById('pg-visible-xs');
    return element.offsetParent !== null;
  }

  isVisibleSm() {
    const element = document.getElementById('pg-visible-sm');
    return element.offsetParent !== null;
  }

  toggleMenuPin() {
    const body = document.body;
    const width = window.innerWidth;
    const pinnedMenu = localStorage.getItem('pinnedMenu');
    if (width > 991 && pinnedMenu) {
      body.classList.add('menu-pin');
    }
  }

  handleClickPin() {
    const body = document.body;
    const toggle = this.state.show ? 'hide' : 'show';
    if (toggle === 'hide') {
      body.classList.remove('menu-pin');
      this.setState({ show: false });
      localStorage.removeItem('pinnedMenu');
    } else if (toggle === 'show') {
      localStorage.setItem('pinnedMenu', true);
      body.classList.add('menu-pin');
      this.setState({ show: true });
    }
  }

  handleMouseEnter() {
    if (this.isVisibleSm() || this.isVisibleXs() || this.isVisibleMd()) {
      return false;
    }
    this.setState({ open: true });
    document.body.classList.add('sidebar-visible');
  }

  handleMouseLeave() {
    if (this.isVisibleSm() || this.isVisibleXs() || this.isVisibleMd()) {
      return false;
    }
    this.setState({ open: false });
    document.body.classList.remove('sidebar-visible');
  }
  toggleSub = name => {
    const type = this.state.subOpen === name ? '' : name;
    this.setState({
      subOpen: type
    });
  };

  handleModal = () => {
    this.props.updateResourceModal({
      showModal: true,
      sectionId: 'add'
    });
  };

  getCurrentEnvName = () => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    this.setState({ env: envName });
  };

  renderMenu = () => {
    const { subOpen } = this.state;
    const { location } = window;
    const pathname = location.pathname;
    const isMyApp = pathname === '/' || pathname.startsWith('/section/');
    const { t } = this.props;
    return (
      <>
        <ul className="m-t-10 menu-items" id="home">
          <li className={subOpen === 'home' ? 'cursor open' : 'cursor'}>
            <NavLink
              exact
              to={`/`}
              className={`link-item home-link ${isMyApp ? 'active' : ''}`}
              activeClassName="active"
            >
              <span className="icon-thumbnail">
                <i className="material-icons">stars</i>
              </span>
              <span className="title">{t('menu.home')}</span>
              <span
                className={'arrow ' + (subOpen === 'home' ? 'open' : '')}
                onClick={() => this.toggleSub('home')}
              ></span>
            </NavLink>
          </li>
        </ul>
      </>
    );
  };

  render() {
    const { env } = this.state;
    let sidebar_class = {
      transform:
        this.state.open === true ? 'translate3d(' + this.state.sideBarWidth + 'px, 0,0)' : 'translate3d(0, 0,0)'
    };
    return (
      <nav
        className="page-sidebar"
        data-pages="sidebar"
        style={sidebar_class}
        onMouseEnter={this.handleMouseEnter.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
        ref={this.sidebar}
      >
        {/*<!-- BEGIN SIDEBAR MENU HEADER-->*/}
        <div className="sidebar-header" style={{ display: 'flex' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={logo}
              alt="logo"
              className="brand"
              data-src={logo}
              data-src-retina={logo2x}
              width="120"
              height="45"
            />
            <span className="environment-name">{env}</span>
          </div>
          <div className="sidebar-header-controls">
            <button
              type="button"
              className="btn btn-link d-lg-inline-block d-xlg-inline-block d-md-none d-xs-none d-sm-none d-none p-r-5 p-l-5 m-l-20"
              data-toggle-pin="sidebar"
              onClick={this.handleClickPin.bind(this)}
            >
              <span
                className={this.state.show ? 'material-icons' : 'material-icons material-icons-outlined'}
                style={{ position: 'relative', top: 3 }}
              >
                push_pin
              </span>
            </button>
          </div>
        </div>
        {/*<!-- END SIDEBAR MENU HEADER-->
        <!-- START SIDEBAR MENU -->*/}
        {/* <!-- START SIDEBAR MENU --> */}
        <div className="sidebar-menu" id="sidebar-menu">
          {this.renderMenu()}
          <div className="clearfix" />
        </div>
        {/*<!-- END SIDEBAR MENU -->*/}
      </nav>
    );
  }
}

export default withTranslation()(Sidebar);
