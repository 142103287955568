import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import PageNotFound from 'pages/PageNotFound';
import Layout from 'components/Layout';
import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'element-theme-default';
import { STORAGE_KEY_APP_SYNC_SIGNED_IN, STORAGE_KEY_LAST_ACTIVE_TIME } from 'core/constants';

function App() {
  useEffect(() => {
    // Login/logout in multiple tabs
    window.addEventListener('storage', e => {
      const { key } = e;
      if (key !== STORAGE_KEY_APP_SYNC_SIGNED_IN) return;
      window.location.reload();
    });
    if (['/login', '/tci', '/saml/idp/sso'].includes(window.location.pathname)) {
      localStorage.removeItem(STORAGE_KEY_LAST_ACTIVE_TIME);
    }
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/tci" component={Login} />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Layout>
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
