import { connect } from 'react-redux';
//import { modules as model } from 'store/master';
import view from './view';

const mapState = state => {
  return {};
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(view);
