import en_US from './i18n/en_US/translation';
import ja_JP from './i18n/ja_JP/translation';
import zh_TW from './i18n/zh_TW/translation';

export const resources = {
  en_US: {
    translation: en_US
  },
  ja_JP: {
    translation: ja_JP
  },
  zh_TW: {
    translation: zh_TW
  }
};
