import { combineReducers } from 'redux';
import notification from 'store/notificationSlice';
import loading from 'store/loadingSlice';
import global from 'store/globalSlice';
import version from 'store/versionSlice';
import user from 'store/userSlice';

export default combineReducers({
  notification,
  loading,
  global,
  version,
  user
});
