import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { logger } from 'core/logger';
import { fetchToken } from './security/fetchToken';
import { cookies } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN, COOKIE_KEY_REFRESH_TOKEN } from 'core/constants';

const instance = axios.create({
  baseURL: process.env.REACT_APP_TCI_IAM_API_URL,
  headers: { 'x-api-key': process.env.REACT_APP_API_KEY }
});

export const tokenCache = {
  token: null,
  exp: null,
  rToken: null,
  rExp: null,
  refreshing: false,
  expired: false
};

const refreshToken = async () => {
  tokenCache.refreshing = true;
  tokenCache.expired = false;
  const rToken = cookies.getKey(COOKIE_KEY_REFRESH_TOKEN);
  if (!rToken) {
    tokenCache.refreshing = false;
    tokenCache.expired = true;
    throw TOKEN_EXPIRED;
  }

  const now = new Date().getTime();
  const decoded_token = jwt_decode(rToken);
  decoded_token.exp = (decoded_token.exp - 1) * 1000;
  if (now > decoded_token.exp) {
    tokenCache.refreshing = false;
    tokenCache.expired = true;
    throw TOKEN_EXPIRED;
  }

  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/refreshToken',
      data: {
        refreshToken: rToken
      }
    },
    false,
    true
  );

  const { userToken, refreshToken } = data;
  cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
  cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
  tokenCache.refreshing = false;
  return Promise.resolve(true);
};

const TOKEN_EXPIRED = { error: { code: 'token-expired' }, response: { data: { code: 'token-expired' } } };

const getToken = async () => {
  if (tokenCache.refreshing) {
    while (tokenCache.refreshing) {
      await new Promise(resolve => {
        setTimeout(resolve, 500);
      });
    }
    if (tokenCache.expired) throw TOKEN_EXPIRED;
    return cookies.getKey(COOKIE_KEY_USER_TOKEN);
  }

  let token = cookies.getKey(COOKIE_KEY_USER_TOKEN);
  if (!token) {
    await refreshToken();
    return cookies.getKey(COOKIE_KEY_USER_TOKEN);
  }

  const now = new Date().getTime();
  const decoded_token = jwt_decode(token);
  decoded_token.exp = (decoded_token.exp - 1) * 1000;
  if (now < decoded_token.exp) {
    return Promise.resolve(token);
  }

  await refreshToken();
  return cookies.getKey(COOKIE_KEY_USER_TOKEN);
};

const RENEW_SECURITY_TOKEN_RETRY = 3;
export const request = async (options, shouldAddAuthorization = true, shouldAddATMToken = true) => {
  const { headers = {} } = options;
  if (shouldAddATMToken && (!headers || !headers['atm-authorization'])) {
    let numRetry = 0;
    let atmToken;
    do {
      atmToken = await fetchToken();
      if (atmToken) {
        headers['atm-authorization'] = `Bearer ${atmToken}`;
      }
      numRetry++;
    } while (numRetry < RENEW_SECURITY_TOKEN_RETRY && !atmToken);
    if (!atmToken) {
      throw TOKEN_EXPIRED;
    }
  }
  try {
    if (shouldAddAuthorization && (!headers || !headers['Authorization'])) {
      const token = await getToken();
      headers['Authorization'] = `Bearer ${token}`;
    }
    options.headers = headers;

    const response = await instance(options);
    logger.debug('API response = ', response.data, options);
    return response.data;
  } catch (error) {
    tokenCache.refreshing = false;
    // Log axios response error
    if (error.response) {
      logger.error('API error = ', error.response);
    }

    // rethrow error
    return error;
  }
};
