import { request } from 'core/request';
import {
  DOMAIN_TRANSCOSMOS,
  MODE_LOGIN,
  COOKIE_KEY_USER_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN
} from 'core/constants';
import store from 'store';
import { getModeLogin } from 'store/globalSlice';
import { cookies } from 'core/utils';

const setCookies = data => {
  let { code = '', userToken = '', refreshToken = '', atmToken = '', atmTokenExpiresAt = '' } = data || {};
  if (code !== 'otp-required') {
    if (userToken) {
      cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
      cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
    }
    if (atmToken) {
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
      cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
    }
  }
};

const getFullEmail = loginId => {
  const state = store.getState();
  const modeLogin = getModeLogin(state);
  let email = loginId;
  if (MODE_LOGIN.LOGIN_BY_LOGIN_ID === modeLogin && !loginId.includes('@')) email = `${loginId}@${DOMAIN_TRANSCOSMOS}`;
  return email;
};

const authenticate = async ({ email: loginId, password, token = '', resourceId = '', authCode }) => {
  loginId = getFullEmail(loginId);
  // get system language
  const lng = localStorage.getItem('sysLanguage') || 'en_US';
  let data = {
    loginId,
    password,
    lng
  };
  if (token) {
    data = {
      token,
      lng
    };
  } else if (authCode) {
    data = {
      authCode,
      lng
    };
  }

  if (resourceId) {
    data = { ...data, resourceId };
  }
  const res = await request(
    {
      method: 'post',
      url: '/dashboard/auth/login',
      data: data
    },
    false,
    false
  );
  setCookies(res.data);
  return res;
};

const renewSecurityToken = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/renewSecurityToken'
    },
    true,
    false
  );
  return data;
};

const logout = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/logout'
    },
    true,
    true
  );
  return data;
};

const logoutAllSessions = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/logoutAllSessions'
    },
    true,
    true
  );
  return data;
};

const refreshToken = async () => {
  const data = await request(
    {
      method: 'post',
      url: '/dashboard/auth/refreshToken'
    },
    false,
    true
  );
  return data;
};

const redirectAuth = async queryStr => {
  const data = await request(
    {
      method: 'get',
      url: '/dashboard/authorize' + queryStr
    },
    false,
    false
  );
  return data;
};

const verifyCode = async authCode => {
  const data = await request(
    {
      method: 'post',
      url: '/saml/code',
      data: { authCode }
    },
    false,
    false
  );

  return data;
};

const getSessionId = async () => {
  const data = await request(
    {
      method: 'get',
      url: '/dashboard/auth/getSessionId',
    },
    false,
    false
  );

  return data;
};

const checkStatusSession = async (sessionId) => {
  const data = await request(
    {
      method: 'get',
      url: '/dashboard/auth/checkLoginStatusBySessionId?sessionId=' + sessionId,
    },
    false,
    false
  );

  return data;
};

export default {
  authenticate,
  refreshToken,
  logout,
  renewSecurityToken,
  redirectAuth,
  verifyCode,
  logoutAllSessions,
  getSessionId,
  checkStatusSession
};
