import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { resources } from './languages/resources';

const options = {
  order: ['navigator'],
  lookupQuerystring: 'lng'
};

i18next.use(LanguageDetector).init({
  // we init with resources
  detection: options,
  resources,
  fallbackLng: 'en_US',
  debug: true,
  // have a com'en'namespace used around the full app
  ns: ['translation'],
  defaultNS: 'translation',
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  },
  react: {
    wait: true
  }
});
let sysLanguage = localStorage.getItem('sysLanguage') || i18next.language;
sysLanguage = sysLanguage.toUpperCase();
let langCode = 'en_US';
if (['JA', 'JA-JP', 'JA_JP'].includes(sysLanguage)) {
  langCode = 'ja_JP';
} else if (
  [
    'ZH',
    'ZH-TW',
    'ZH-CN',
    'ZH-HK',
    'ZH-MO',
    'ZH-SG',
    'ZH-CHS',
    'ZH-HANS',
    'ZH-CHT',
    'ZH-HANT',
    'ZH_TW',
    'ZH_CN',
    'ZH_HK',
    'ZH_MO',
    'ZH_SG',
    'ZH_CHS',
    'ZH_HANS',
    'ZH_CHT',
    'ZH_HANT'
  ].includes(sysLanguage)
) {
  langCode = 'zh_TW';
}
i18next.changeLanguage(langCode);
localStorage.setItem('sysLanguage', langCode);
export default i18next;
