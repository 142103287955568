import Cookies from 'js-cookie';
import {
  COOKIE_KEY_USER_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN
} from 'core/constants';

export const setKey = (key, value, maxAge = 86400) => {
  var expires = new Date();
  expires.setSeconds(expires.getSeconds() + maxAge);
  if (process.env.NODE_ENV === 'development') {
    Cookies.set(key, value, { path: '/', expires });
  } else {
    Cookies.set(key, value, { path: '/', expires, secure: true, sameSite: 'strict' });
  }
};

export const getKey = key => {
  return Cookies.get(key);
};

export const removeKey = key => {
  Cookies.remove(key);
};

export const removeAllTokens = () => {
  // User token
  removeKey(COOKIE_KEY_USER_TOKEN);
  removeKey(COOKIE_KEY_REFRESH_TOKEN);
  // ATM Token
  removeKey(COOKIE_KEY_APP_ATM_TOKEN);
  removeKey(COOKIE_KEY_APP_ATM_TOKEN_EXP);
};

export default {
  setKey,
  getKey,
  removeKey,
  removeAllTokens
};
