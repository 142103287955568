import { request } from 'core/request';

const getUser = async (tokenId) => {
  const data = await request({
    method: 'get',
    url: 'dashboard/users/userByTokenId/' + tokenId,
  },
  false,
  false
  );
  return data;
};

export default {
    getUser
};
