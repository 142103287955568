import { connect } from 'react-redux';
import view from './view';
import { selectNotification, updateNotification } from 'store/notificationSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) }
  };
};

const mapDispatch = {
  updateNotification: updateNotification
};

export default connect(mapState, mapDispatch)(view);
