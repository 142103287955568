import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18n from './i18n';
import './assets/index.scss';
import { logger } from 'core/logger';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'store';
// import { Amplify } from 'aws-amplify';
// import amplifyconfig from './amplifyconfiguration.json';

// Amplify.configure(amplifyconfig);

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <CookiesProvider>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </I18nextProvider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.REACT_APP_IS_PWA === 'true') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}

if (process.env.REACT_APP_IS_NOT_LOCAL === 'false') {
  const envs = Object.entries(process.env)
    .filter(([key]) => key.startsWith('REACT_APP_'))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  logger.debug('ENVIRONMENTS = ', envs);
}
