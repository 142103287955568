import { connect } from 'react-redux';
import view from 'pages/Home/view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading } from 'store/loadingSlice';
import { selectCurrentUser } from 'store/userSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    currentUser: selectCurrentUser(state)
  };
};

const mapDispatch = {
  updateNotification: updateNotification,
  updateFullLoading: updateFullLoading
};

export default connect(mapState, mapDispatch)(view);
