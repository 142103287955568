import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import i18n from '../../i18n';

const ConfirmModal = ({
  onClose,
  message,
  title,
  confirmText,
  cancelText,
  confirmColor,
  cancelColor,
  className,
  customButtons
}) => {
  let defaultButtons = (
    <Fragment>
      {cancelText && (
        <Button color={cancelColor} onClick={() => onClose(false)}>
          {cancelText}
        </Button>
      )}{' '}
      <Button color={confirmColor} onClick={() => onClose(true)}>
        {confirmText}
      </Button>
    </Fragment>
  );

  return (
    <Modal isOpen toggle={() => onClose(false)} className={`reactstrap-confirm ${className}`} zIndex={2050}>
      {title && <ModalHeader toggle={() => onClose(false)}>{title || null}</ModalHeader>}
      <ModalBody>{message}</ModalBody>
      <ModalFooter>{customButtons ? customButtons({ onClose }) : defaultButtons}</ModalFooter>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  message: 'Are you sure?',
  title: 'Warning!',
  confirmText: 'Ok',
  cancelText: 'Cancel',
  confirmColor: 'primary',
  cancelColor: '',
  className: '',
  customButtons: null
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.node,
  title: PropTypes.node,
  confirmText: PropTypes.node,
  cancelText: PropTypes.node,
  confirmColor: PropTypes.string,
  cancelColor: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  customButtons: PropTypes.func // Ex: customButtons: ({onClose}) => <Button>...</Button>
};

const Confirm = props => {
  const defaultProps = {
    message: i18n.t('message.confirm.default'),
    title: i18n.t('common.message.confirm.header'),
    confirmText: i18n.t('common.button.yes'),
    cancelText: i18n.t('common.button.no'),
    confirmColor: 'primary',
    cancelColor: '',
    className: '',
    customButtons: null,
    ...props
  };

  return new Promise(resolve => {
    let el = document.createElement('div');
    el.style.backgroundColor = '#e5e5e5';

    const resolveResult = result => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    render(<ConfirmModal {...defaultProps} onClose={resolveResult} />, el);
  });
};

export default Confirm;
