import { connect } from 'react-redux';
import view from './view';
import { updateNotification, selectNotification } from 'store/notificationSlice';
import { updateFullLoading, selectFullLoading } from 'store/loadingSlice';
import { getModeLogin, setLoginIdStore } from 'store/globalSlice';
import { selectCurrentUser, updateUser } from 'store/userSlice';

const mapState = state => {
  return {
    notification: { ...selectNotification(state) },
    fullloading: selectFullLoading(state),
    modeLogin: getModeLogin(state),
    currentUser: selectCurrentUser(state)
  };
};

const mapDispatch = {
  updateNotification,
  updateFullLoading,
  setLoginIdStore,
  updateUser
};

export default connect(mapState, mapDispatch)(view);
