/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    loginId: null,
    firstName: null,
    lastName: null
  },
  reducers: {
    updateUser(state, { payload: userInfo }) {
        state.id = userInfo.id;
        state.loginId = userInfo.loginId;
        state.firstName = userInfo.firstName;
        state.lastName = userInfo.lastName;
    }
  }
});

export const { updateUser } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = state => state.user;
