/* convert string from full to half width
 *		１２３ to 123
 *   'space' full to half width
 */
export const toHalfWidth = str => {
  const value = str
    .replace(/[\uff01-\uff5e]/g, ch => String.fromCharCode(ch.charCodeAt(0) - 0xfee0))
    .replace(/\u3000/g, '\u0020')
    .replace(/[ー−]/g, '\u002D');
  return value;
};

export default {
  toHalfWidth
};
