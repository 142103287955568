import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'notification',
  initialState: {
    showModal: false,
    heading: '',
    displayText: '',
    validStatus: null,
    buttonOkText: '',
    buttonCloseText: '',
    noCancel: false,
    type: '',
    callback: null,
    callbackClose: null,
    modalClassName: ''
  },
  reducers: {
    updateNotification(state, { payload }) {
      const {
        showModal = false,
        heading = '',
        displayText = '',
        validStatus = null,
        buttonOkText = '',
        buttonCloseText = '',
        noCancel = false,
        type = '',
        callback = null,
        callbackClose = null,
        modalClassName = ''
      } = payload;
      state.showModal = showModal;
      state.heading = heading;
      state.displayText = displayText;
      state.validStatus = validStatus;
      state.buttonOkText = buttonOkText;
      state.buttonCloseText = buttonCloseText;
      state.noCancel = noCancel;
      state.type = type;
      state.callback = callback;
      state.callbackClose = callbackClose;
      state.modalClassName = modalClassName;
    }
  }
});
export const { updateNotification } = slice.actions;
export default slice.reducer;

export const selectNotification = state => state.notification;
