import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import "./index.scss"
import QRCode from "react-qr-code";
import { use } from 'i18next';
import { auth as authService } from 'services';
import { da } from 'date-fns/locale';

import {CUSTOM_URL_SCHEMA} from 'core/constants';
import { event } from 'react-ga';
import { cookies } from 'core/utils';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import {
  COOKIE_KEY_USER_TOKEN,
  STORAGE_KEY_APP_SYNC_SIGNED_IN,
  STORAGE_KEY_LAST_ACTIVE_TIME,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  COOKIE_KEY_REFRESH_TOKEN,
  MFA_METHODS,
  MODE_LOGIN,
  DOMAIN_TRANSCOSMOS,
  USER_ACCOUNT_LOGIN_BY_ID_MAX_LENGTH,
  EMAIL_MAX_LENGTH
} from 'core/constants';


class QRCodePopup extends React.Component {
  static propTypes = {
    i18n: PropTypes.object,
    t: PropTypes.any,
    setShowQR: PropTypes.func,
    history: PropTypes.any
  };
  constructor(props) {
    super(props);
    this.state = {
      languages: [
        {
          value: 'en_US',
          label: 'English'
        },
        {
          value: 'ja_JP',
          label: '日本語'
        },
        {
          value: 'zh_TW',
          label: '繁體中文'
        }
      ],
      sessionId: ''
    };
  }
  changeLanguage = res => {
    const i18next = this.props.i18n;
    const value = res.value;
    localStorage.setItem('sysLanguage', value);
    i18next.changeLanguage(value);
  };

  fetchSessionId = async() => {
    try {
        const res = await authService.getSessionId();
        const data = await res.data;
        //this.setState( {sessionId: CUSTOM_URL_SCHEMA + data.sessionId})
        this.setState( {sessionId: data.sessionId})
    } catch (error) {
        this.setState( {sessionId: "error"})
        console.error('Error fetching sessionId');
    }
  };

  checkStatusOfSession = async(sessionId) => {
    try {
        const res = await authService.checkStatusSession(sessionId);
        //const data = await res.data;
        return res;
    } catch (error) {
        console.log(error);
        console.error('Error fetching sessionId');
    }
  };

  setCookies = data => {
    const { userToken = '', refreshToken = '', atmToken = '', atmTokenExpiresAt = '' } = data;
    localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, new Date().getTime());
    localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
    cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken, 5000);
    cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken, 5000);
    cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
    cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
  };

  loginSuccess = data => {
    console.log("login success");
    //const history = useHistory();
    localStorage.setItem('action', 'LOGIN');
    this.setCookies(data);
    this.props.history.push("/");
  };

  componentDidMount () {
    this.fetchSessionId();

    let countSecond = 0;

    this.interval =  setInterval(async () => {
      try {
        if ( countSecond > 180) {
          clearInterval(this.interval);
          this.props.setShowQR();
          return;
        }
        const data = await this.checkStatusOfSession(this.state.sessionId);
        if (data?.status === "OK")
        {
          switch (data.code) {
            case "success":
              clearInterval(this.interval);
              this.loginSuccess(data.data);
              this.props.setShowQR();
              break;
            
            case "wait": 
              break;

            case "login-failed":
              toast.error(data.data.code);
              clearInterval(this.interval);
              this.props.setShowQR();
              break;
          
            default:
              break;
          }
        } else {
          switch (data.code) {
            case "session-expired":
              toast.error(data.data.code);
              clearInterval(this.interval);
              this.props.setShowQR();
              break;
          
            default:
              break;
          }
        }
               
      } catch (error) {
        // clearInterval(this.interval);
        // this.props.setShowQR();
      }
      countSecond++;

    }, 1000);
  }

  

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  
  
  render() {
    const { t, setShowQR } = this.props;
    const lang = localStorage.getItem('sysLanguage') || 'en_US';
    const langValue = this.state.languages.filter(a => a.value === lang)[0];
    
    const { sessionId } = this.state;

    const closePopup = () => {
        console.log("close popup");
        setShowQR();
    }

    return (
      <div id="myModal" className="modal">
      <div className="modal-content">
        <div className='body-modal-container'>
            <div className='icon-text-container'>
                <div className='icon-container'>
                    <img
                        style={{ maxHeight: '70px' }}
                        className="large-icon"
                        src={require('assets/img/TCI-PF-certificate.png')}
                        alt="TCI-PF System Cert t-passport"
                    />
                </div>
                <div className='text-container'>
                    <p>スマートフォンの認証アプリよりQRの読み取りでログイン認証を行えます。</p>
                </div>
            </div>
            <div className='qr-code-container'>
                <div style={{ height: "auto", margin: "0 auto", maxWidth: 256, width: "100%" }}>
                    {sessionId === "error" ? <p style={{textAlign: "center"}}>QRコード生成に失敗しました。<br/>再度試してください。</p>: (sessionId.length === 0? <p></p> : <QRCode
                        size={1024}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={CUSTOM_URL_SCHEMA + sessionId}
                        viewBox={`0 0 256 256`}
                    />)}
                </div>
            </div>
            <div className='button-container'>
                <button className="btn btn-info btn-cons m-t-12 btn-block" onClick={closePopup}>キャンセル</button>
            </div>
        </div>
      </div>
    </div>
    );
  }
}

export default withTranslation()(QRCodePopup);
